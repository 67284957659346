import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"

import dtvIcon from "../images/about/logo-dtv.png"
import Footer from "../components/Footer"

const browser = typeof window !== "undefined" && window

const NotFoundPage = () => {
  return (
    browser && (
      <div>
        <Container maxWidth="lg">
          <Box my={30}>
            <SEO title="404 Page not found" />
            <h1>404 Error content...</h1>
            <Typography variant="h4" gutterBottom>Lets go back to <Link to="/app">main page</Link> <Box mt={-1} clone><img src={dtvIcon} /></Box></Typography>
          </Box>
          <Box mb={60} />
          <Footer />
        </Container>
      </div>
    )
  )
}

export default NotFoundPage
